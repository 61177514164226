.top-btn-position{
    position: fixed;
    bottom: 38px;
    right: 20px;
}

.top-btn-style{
    height: 40px;
    width: 40px;
    padding: 8px;
    border-radius: 50%;
    border: none;
    background-color:#1b61d7; /*#418ACF;*/
    color: #fff;
    cursor: pointer;
    z-index: 20;
    transition: 0.3s ease-in-out;
}

.top-btn-style:hover{
  background: #D1B864;
}

/* Adjust the button position for smaller screens */
@media (max-width: 768px) {
    .top-btn-position {
      bottom: 20px;
      right: 20px;
    }
  }