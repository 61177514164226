.container {
  position: relative;
}

.scrollable-tabs-container{
    font-family: 'Open Sans',sans-serif;
    background: #1b61d7;
    margin: 0px auto;
    overflow: hidden;
    position: relative;
}

.scrollable-tabs-container svg{
    width: 36px;
    height: 36px;
    padding: 8px;
    cursor: pointer;
    color: #fff;
    border-radius: 50%;
}

.scrollable-tabs-container ul{
    display: flex;
    gap: 0.5rem;
    padding: 12px 24px;
    margin: 0;
    list-style: none;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    
}

.scrollable-tabs-container ul::-webkit-scrollbar{
    display: none;
}

.scrollable-tabs-container a{
    font-size: 12px;
    color: #fff;
    text-decoration: none;
   /* background: #333; */
    padding: 4px 24px;
    display: inline-block;
    border-radius: 4px;
    user-select: none;
    white-space: nowrap;
    transition: background-color 0.3s ease, color 0.3s ease; 
}

.scrollable-tabs-container a.active{
    background-color: #D1B864;
    color: #000;
}

.scrollable-tabs-container a:hover{
    background-color: #D1B864;
    color: #000;
}

.scrollable-tabs-container .right-arrow, 
.scrollable-tabs-container .left-arrow{
        position: absolute;
        height: 100%;
        width: 100px;
        top: 0;
        display: none;
        align-items: center;
        padding: 0 10px;
}

.scrollable-tabs-container .right-arrow.active,
.scrollable-tabs-container .left-arrow.active{
        display: flex;
}

.scrollable-tabs-container .right-arrow{
    right:0;
    background: linear-gradient(to left, #1b61d7 50%, transparent);
    justify-content: flex-end;
}

.scrollable-tabs-container .left-arrow{
    left: 0;
    background: linear-gradient(to right, #1b61d7 50%, transparent);
}

.scrollable-tabs-container svg:hover{
    background: #333;
}

.cards-item-container{
    width: 95%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.item-cards{
    background-color: #DCE0E1;
    text-align: center;
}

.item-cards img{
    width: 100%;
    object-fit: cover;
    height: 180px;
}

.item-cards .title{
    font-weight: 100;
    line-height: 12px;
}
/*
.item-cards button{
    background-color: #1b61d7;
    color: #fff;
    padding: 5px;
    width: 100%;
    border: none;
    border-radius: 3px;
    width: 90%;
    margin-bottom: 10px;
    margin-top: 10px;
}*/

.card-buttons {
    display: flex;
    justify-content: space-evenly; /* Adjust this property as needed */
    margin-top: 10px; /* Add margin-top as needed */
    width: 100%;
  }

  .card-buttons button {
    background-color: #1b61d7;
    color: #fff;
    padding: 5px;
    width: 45%; /* Adjust width as needed */
    border: none;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .card-buttons button.program-details {
    background-color: #ccc; /* Adjust the color to a greyish tone */
    color: #000;
}

.card-buttons button.donate {
  cursor: pointer;
}

.skill-box{
    width: 100%;
    padding-top: 25px;
    padding-left: 16px;  
    padding-right: 16px; 
}

.skill-box .skill-bar{
    height: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 6px;
    background: rgba(0, 0, 0, 0.1);
}

.skill-box .skill-per{
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 6px;
    background: #1b61d7;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
    transition: width 0.3s ease; 
}

.skill-per.reactjs{
    width: 70%;
    animation-delay: 0.3s;
}


@keyframes progress{
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per .tooltip{
    position: absolute;
    right: -14px;
    top: -26px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    background: #D1B864;
    z-index: 1;
}

.tooltip::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: #D1B864;
    transform: translateX(-50%) rotate(45deg);
}

.target-texts {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;  
    padding-right: 16px; 
}

.text-item {
    text-align: center;
    margin-bottom: 10px;
}

.amount {
    display: block;
    font-weight: bold;
    color: #1b61d7; /* Adjust the color as needed */
}

.text-item span {
    font-size: 10px; /* Adjust the font size as needed */
}

.no-items-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px; /* Adjust the height as needed */
    font-size: 18px;
    color: #555; /* Adjust the color as needed */
    text-align: center;
  }

  .more-content-section{
    height: 100vh;
    background-color: #1b61d7;
   /* background:linear-gradient(#D1B864,#DCE0E1);*/
  }
  

@media (max-width: 768px) {
    .cards-item-container {
      grid-template-columns: repeat(2, 1fr);
    }

    .item-cards{
      margin-top: 8px;
    }

    .item-cards img {
      height: 300px;
    }

    .item-cards .title{
      font-weight: 300;
      font-size: 18px;
      margin: 8px;
    }

  .card-buttons button.program-details, .card-buttons button.donate {
    padding: 10px;
  }

  .text-item span {
    font-size: 14px; /* Adjust the font size as needed */
  }

  .scrollable-tabs-container a{
    font-size: 12px;
    padding: 4px 10px;

}

  
  }

  @media (max-width: 480px) {
    .cards-item-container {
        grid-template-columns: repeat(1, 1fr);
    }
}


@import url('https://fonts.googleapis.com/css?family=Montserrat:600&display=swap');

.floating-social-media-icons{
    display: none;
}

.floating-social-media-icons nav{
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-20%);
    width: 70px;
    transition: all 0.3s linear;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, .4);
    z-index: 10000;
}
.floating-social-media-icons nav li{
  height: 60px;
  position:relative;
}
.floating-social-media-icons nav li a{
  color: white;
  display: block;
  height: 100%;
  width: 100%;
  line-height: 60px;
  padding-left:25%;
  border-bottom: 1px solid rgba(0,0,0,.4);
  transition: all .3s linear;
}
.floating-social-media-icons nav li:nth-child(1) a{
  background: #4267B2;
}
.floating-social-media-icons nav li:nth-child(2) a{
  background: #1DA1F2;
}
.floating-social-media-icons nav li:nth-child(3) a{
  background: #E1306C;
}
.floating-social-media-icons nav li:nth-child(4) a{
  background: #2867B2;
}
.floating-social-media-icons nav li:nth-child(5) a{
  background: #333;
}
.floating-social-media-icons nav li:nth-child(6) a{
  background: #ff0000;
}
.floating-social-media-icons nav li a i{
  position:absolute;
  top: 17px;
  left: 20px;
  font-size: 27px;
}
.floating-social-media-icons ul li a span{
  display: none;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.floating-social-media-icons a:hover {
  z-index:1;
  width: 200px;
  border-bottom: 1px solid rgba(0,0,0,.5);
  box-shadow: 0 0 1px 1px rgba(0,0,0,.3);
}
.floating-social-media-icons ul li:hover a span{
  padding-left: 30%;
  display: block;
}


.loading-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8); /* semi-transparent white background */
  padding: 20px;
  border-radius: 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000; /* Adjust z-index as needed to ensure it's above other elements */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db; /* blue color for spinner */
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.load-more-items{
  background: tomato;
  width: 150px;
  height: 45px;
  padding: 10px;
  color: white;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

/* ... (previous CSS code) */

/*
.load-more-items-container {
  margin: 20px auto;
  text-align: center;
}*/

 .load-more-items-container {
  width: 100%;
  border: 2px dashed #3498db; /* Adjust border color as needed */
  border-radius: 8px; /* Adjust border-radius to match the item cards */
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
}

.load-more-items-container:hover {
  background-color: #3498db; /* Adjust background color on hover as needed */
  color: #fff;
  border: 2px solid #3498db; /* Change to solid border on hover if needed */
}

.load-more-items-card i {
  margin-bottom: 5px;
}

/* ... (previous CSS code) */


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Home.css */

.no-interaction {
  pointer-events: none;
}


