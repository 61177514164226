.donation-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 30px;
    text-align: justify;
    user-select: none;
}

.project-info{
    max-width: 100%;
}

.project-info p.donation-link{
  margin-bottom: 10px;
}

.project-info p.donation-link a{
  text-decoration: none;
}

.project-info p.donation-link a:hover{
  color: #1b61d7;
}

.project-info img {
    width: 100%;
    height: 22rem;
    object-fit: cover;
  }

  .project-info h2{
    font-size: 20px;
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
  }

  .project-info p{
    font-weight: 100;
    text-align: left;
  }

  .project-info p span{
    font-weight: 500;
  }

.payment-options{
    width: 90%;
    max-width: 100%;
   /* background-color: aqua; */
}

.payment-options p{
    font-weight: 500;
}

.payment-card {
    cursor: pointer;
    margin-bottom: 20px;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .payment-card img {
    width: 50px; /* Adjust the size as needed */
    height: auto;
    margin-right: 10px;
  }
  
  .payment-card.selected {
    border-color: #4caf50; /* Border color when selected */
  }
  
  .payment-card p {
    display: flex;
    align-items: center;
  }
  
  .payment-card input {
    margin-right: 5px;
  }

  .payment-options form input{
    display: none;
  }

  form .category{
    margin-top: 10px;
   /* padding-top: 20px; */
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   grid-gap: 15px;
  }

  .category label {
    /*width: 100%; */
   /* height: 65px; */
   
    /*height:145px ;
    padding: 20px; */

    height: 90px;
    width: 90px;
    
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
    display: flex;
   /* justify-content: space-between;*/
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
  }

  .payment-img{
    width: 60%;
    height: auto;
    user-select: none;
  }

/*  label:nth-child(2), label:nth-child(3){
    margin: 15px 0;
  } */

  #visa:checked ~ .category .visaMethod,
#mastercard:checked ~ .category .mastercardMethod,
#paypal:checked ~ .category .paypalMethod,
#AMEX:checked ~ .category .amexMethod{
    box-shadow: 0px 0px 0px 1px #6064b6;
}

 .category .visaMethod:hover,
.category .mastercardMethod:hover,
 .category .paypalMethod:hover,
 .category .amexMethod:hover{
    box-shadow: 0px 0px 0px 1px #6064b6;
}

#visa:checked ~ .category .visaMethod .check,
#mastercard:checked ~ .category .mastercardMethod .check,
#paypal:checked ~ .category .paypalMethod .check,
#AMEX:checked ~ .category .amexMethod .check{
    display: block;
}


  label .imgName{
    display: flex;
    /*justify-content: space-between; */
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;

  }

  .imgName span{
   /* margin-left: 20px; */
    font-family: Arial, Helvetica, sans-serif;
    position: absolute;
    top: 72%;
    transform: translateY(-72%);
  }

  .imgName .imgContainer{
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 35%;
    transform: translateY(-35%);
  }

  .visa img{
    width: 60px;
    /*margin-left: 3px; */
  }

  .paypal img{
    width: 60px;
  }

  .AMEX img{
    width: 40px;
  }

  .mastercard img{
    width: 55px;
  }

  .check{
    display: none;
    position: absolute;
    top: -4px;
    right: -4px;
  }

  .check i{
    font-size: 18px;
  }

  label span.name{
    font-size: 12px;
    user-select: none;
  }

  .skill-box1{
    width: 100%;
    padding-top: 25px;
  /*  padding-left: 16px;  */
    padding-right: 16px; 
}

.skill-box1 .skill-bar1{
    height: 12px;
    width: 100%;
    border-radius: 2px;
    margin-top: 6px;
    background: rgba(0, 0, 0, 0.1);
}

.skill-box1 .skill-per1{
    position: relative;
    display: block;
    height: 100%;
    width: 95%;
    border-radius: 2px;
    background: #1b61d7;
    animation: progress 0.4s ease-in-out forwards;
    opacity: 0;
}

.skill-per1.reactjs1{
    width: 70%;
    animation-delay: 0.3s;
}


@keyframes progress{
    0% {
        width: 0;
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.skill-per1 .tooltip1{
    position: absolute;
    right: -14px;
    top: -26px;
    font-size: 9px;
    font-weight: 500;
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    background: #D1B864;
    z-index: 1;
}

.tooltip1::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    height: 10px;
    width: 10px;
    z-index: -1;
    background-color: #D1B864;
    transform: translateX(-50%) rotate(45deg);
}

.target-texts1 {
  display: flex;
  justify-content: space-between;
  /* padding-left: 16px;  */
  padding-right: 16px; 
}

.text-item1 {
  text-align: center;
  margin-bottom: 10px;
}

.amount1 {
  display: block;
  font-weight: bold;
  color: #1b61d7; /* Adjust the color as needed */
}

/*
.text-item1 span {
  font-size: 10px; 
} */


.donate-button {
  background-color: #1b61d7;
  color: #fff;
  font-size: 16px;
  padding: 12px 40px;
  margin-top: 20px; /* Add margin to create space */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.donate-button:hover {
  background-color: #004080; /* Darker shade of the primary color on hover */
}

/* Add this to your existing CSS */

.or-divider {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.or-divider .line {
  flex-grow: 1;
  height: 1px;
  background-color: #ccc;
}

.or-divider .or-text {
  padding: 0 5px;
  color: #888;
  font-weight: bold;
}

/* Add this to your existing CSS */

.bank-details-text {
  text-align: center;
  color: #888;
  font-weight: bold;
}

.bank-details-text a {
  color: #1b61d7; /* Set the color of the link */
  text-decoration: none; /* Add underline to the link */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.bank-details-text a:hover {
  color: #004080; /* Darker shade of the link color on hover */
}

@media (max-width: 768px) {

  .donation-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
    text-align: justify;
    user-select: none;
  }


.skill-box1{
  width: 100%;
  padding-top: 25px; 
}


.payment-options{
  width: 100%;
  max-width: 100%;
 /* background-color: aqua; */
}

  .category label {
    height: 60px;
    width: 60px;
  }

  .category .visa img,
  .category .paypal img,
  .category .AMEX img,
  .category .mastercard img {
    width: 40px;
  }

  .category .AMEX img {
    width: 30px;
  }

  .check i {
    font-size: 16px;
  }

  label span.name {
    font-size: 10px;
  }

  .donate-button {
    width: 100%;
    font-size: 15px;
    padding: 8px 16px;
  }

  .or-divider {
    margin: 10px 0;
  }

  .or-divider .or-text {
    font-size: 12px;
  }

  .bank-details-text {
    font-size: 12px;
  }

  .donation-container {
    grid-template-columns: 1fr;
  }

  .project-info p{
    font-weight: 100;
    text-align: justify;
  }

  .donate-button {
    font-size: 16px;
    padding: 14px 50px;
  }

}