/* Topbar.css */
.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; /* Change this to your desired background color */
    color: #898989; /* Change this to your desired text color */
    padding: 10px 20px; /* Adjust padding as needed */
    border-bottom: 1px solid #e6e1e1;
  }
  
  .logo_name {
    font-size: 13px; /* Adjust font size as needed */
    font-weight: normal;
    font-style: normal;
    cursor: pointer;
  }
  
  .topbar_links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Display the links as flex items */
  }
  
  .topbar_links li {
    margin-right: 15px; /* Adjust margin between links as needed */
  }
  
  .topbar_links a {
    text-decoration: none;
    color: #898989; /* Change this to your desired link color */
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    transition: all 150ms;
  }

  .topbar_links a:hover {
    color: #333; /* Change this to your desired hover color */
  }
  
  