body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* WebKit (Chrome, Safari) Scrollbar */
::-webkit-scrollbar {
  width: 12px; /* width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #D1B864; /* color of the thumb */
  border-radius: 5px; /* round the corners of the thumb */
  
}

::-webkit-scrollbar-track {
  background-color: #ccc; /* color of the track */
}

/* Firefox Scrollbar */
body {
  scrollbar-width: thin;
}

body {
  scrollbar-color: #D1B864 #1b61d7; /* thumb and track colors */
}
