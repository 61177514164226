.modal-container{
   /* display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;*/
  /*  background-color: rgba(0, 0, 0, 0.5);
     position: fixed;
     width: 100%;
     height: 100%;
     z-index: 900;*/
     position: fixed;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     z-index: 10000;
} 

.modal-background{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position:fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;
   
}

.modal-wrapper{
    width: 600px;
    height: 400px;
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #fff;
    color: #000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 90;
    border-radius: 10px;
    cursor: default;
}

.modal-img{
    width: 100%;
    height: 100%;
    max-height: 401px;
    border-radius: 10px 0 0 10px;
    background: #000;
    object-fit: cover;
}

.modal-content{
    display: flex;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.8;
    color: #141414;
    padding: 0.2rem 1rem;
    margin-top: 30px;
    position: relative;
}

.modal-content p{
    text-align: justify;
    font-size: 13px;
}


.btnContainer{
    position: absolute;
    width: 100%;
    display: flex;
    gap: 1rem;
    margin-top: auto; /* Move the buttons to the bottom */
    position: absolute; /* Position relative to .modal-content */
    bottom: 0; /* Set the buttons at the bottom */
    left: 0; /* Align buttons to the left */
    box-sizing: border-box; 
    padding: 0 17px;
    margin-bottom: 10px;
}

.btnContainer button{
    flex: 1;
    width: 100%;
    padding: 12px 0;
    border: none;
    color: #fff;
    border-radius: 5px;
}


.program-desc{
    line-height: 1.3em;
}

.btnPrimary{
    background-color: #1b61d7;
}

.btnOutline {
    background-color: #D1B864;
}


.modal_close_btn{
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    padding: 0;
    z-index: 10;
    transition: all 0.3ms ease-in-out;
}

.modal_close_btn:hover{
        color: #1b61d7;
}


@media (max-width: 768px) {
    .modal-wrapper {
        height: auto;
        grid-template-columns: 1fr; /* Change to a single column layout */
        width: 87%; /* Adjust width as needed for mobile devices */
        margin: 0 auto; /* Center the modal on smaller screens */
        overflow-y: auto;
        max-height: 100%;
    }

    .modal-img {
        border-radius: 10px 10px 0 0; /* Adjust border-radius for the top corners */
        height: 100%;
        max-height: 250px;
    }

    .modal-content {
        margin-top: 20px; /* Add some spacing between image and content */
        padding: 0.1rem 1rem;
    }

    .modal-content p {
        margin-bottom: 0px;
        font-size: 12px;
      }

    .btnContainer {
        position: relative;
     /*   flex-direction: column; /* Stack buttons vertically on mobile */
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.2rem;
        padding: 0 2px;
        margin-top: 5px;
    }

    .btnContainer button {
        width: 100%; /* Full width buttons on mobile */
    }

    .program-desc, .modal-content p {
        font-size: 12px;
        margin-top: -20px;
    }

    

}