/* Bank_details_page.css */
/* Styling for the Donate section */
.donate-section {
  background-color: #1b61d7; /* Choose your desired background color */
  color: #fff; /* Text color for contrast */
  padding: 20px;
  text-align: center;
}

.bank-details-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  /* Style for MTN MOMO logo */
.mtn-momo-logo {
    width: 60px; /* Adjust the size as needed */
    height: auto;
    margin-right: 8px; /* Add margin to separate the icon from text */
  }

  .stanbic-icon{
    width: 100px;
    height: auto;
    margin-right: 8px;
  }
  
  
  /* Add media queries for responsiveness */
  @media (max-width: 600px) {
    td, th {
      display: block;
      width: 100%;
      box-sizing: border-box;
    }

    /* Adjust styling for the MTN MOMO logo in mobile view */
  .mtn-momo-logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px; /* Add margin for better spacing */
  }
  }
  